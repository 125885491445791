
import { defineComponent, ref, computed, Ref, ComputedRef } from 'vue';
import router, { NavigationFeatureRoutes } from '@/router';
import { authStore } from '@/store';
import config from '@/config';
import ModalDialog from '@/components/common/ModalDialog.vue';
import { windowSizeModule } from '@/modules';
import knowledgeBaseService from '@/services/knowledge-base-service';
import routes from '@/router/routes';
import i18n from '@/i18n';
import util from '@/util';
import TenantStoreModel from '@/store/auth-store/models/TenantStoreModel';
import RoutePaths from '@/router/routes';
import locator from '@/util/locator';
import NavbarJobsItem from './NavbarItems/JobsItem.vue';
import { RouteRecordName } from 'vue-router';

function setupUiReactivity({ tenantSearchInput, currentTenant }: { tenantSearchInput: Ref<string>; currentTenant: ComputedRef<TenantStoreModel | undefined> }) {
    // state
    const title = process.env.VUE_APP_NAME!;
    const clientId = process.env.VUE_APP_CLIENT_ID!;

    const showSigninInformation = ref(false);
    const showHamburgerMenu = ref(false);
    const showTenantsSwitchList = ref(false);

    const tenantSearchInputRef = ref<HTMLInputElement | null>(null);

    // functions
    function toggleShowSigninInformation(): void {
        // eslint-disable-next-line no-param-reassign
        showSigninInformation.value = !showSigninInformation.value;

        // if it was set to false, we should close everything
        if (!showSigninInformation.value) {
            closeSigninInformation();
        }
    }

    function closeSigninInformation(): void {
        showSigninInformation.value = false;
        showHamburgerMenu.value = false;
        showTenantsSwitchList.value = false;
        tenantSearchInput.value = currentTenant.value!.organizationName;
    }

    function toggleHamburgerMenu(): void {
        // eslint-disable-next-line no-param-reassign
        showHamburgerMenu.value = !showHamburgerMenu.value;
    }

    function hideSignInInformationMenu(): void {
        showSigninInformation.value = false;
    }

    function hideHamburgerMenu(): void {
        showHamburgerMenu.value = false;
    }

    function toggleTenantsList(): void {
        showTenantsSwitchList.value = !showTenantsSwitchList.value;

        if (showTenantsSwitchList.value) {
            tenantSearchInputRef.value!.select();
        }
    }

    function showTenantsList(): void {
        if (!showTenantsSwitchList.value) {
            showTenantsSwitchList.value = true;
            tenantSearchInputRef.value!.select();
        }
    }

    function hideTenantsList(): void {
        showTenantsSwitchList.value = false;
    }

    return {
        // state
        title,
        clientId,
        showSigninInformation,
        showHamburgerMenu,
        showTenantsSwitchList,
        tenantSearchInputRef,

        // functions
        toggleShowSigninInformation,
        closeSigninInformation,
        toggleHamburgerMenu,
        toggleTenantsList,
        hideSignInInformationMenu,
        hideHamburgerMenu,
        showTenantsList,
        hideTenantsList,

        // modules
        ...windowSizeModule(),
    };
}

function setupAuthenticationContext() {
    // state
    const tenantSearchInput = ref('');

    // computed
    const user = computed(() => {
        const userDetails = authStore.storeApi.get(authStore.getters.authenticatedUser);
        return userDetails;
    });

    const userFullName = computed(() => {
        return `${user.value.firstName} ${user.value.lastName}`;
    });

    const currentTenant = computed(() => {
        const tenant = authStore.storeApi.get(authStore.getters.currentTenant);

        tenantSearchInput.value = tenant!.organizationName;

        return tenant!;
    });

    const hasOtherTenants = computed(() => {
        const tenants = authStore.storeApi.get(authStore.getters.otherTenants);
        return tenants.length > 0;
    });

    const otherTenants = computed(() => {
        const tenants = authStore.storeApi.get(authStore.getters.otherTenants);

        if (!tenantSearchInput.value || util.isNullOrEmpty(tenantSearchInput.value) || tenantSearchInput.value === currentTenant.value?.organizationName) {
            return tenants;
        }

        return tenants.filter((tenant) => tenant.organizationName.toLowerCase().includes(tenantSearchInput.value.trim().toLowerCase()));
    });

    // functions
    function computeTenantContextLink(tenantId: string): string {
        return locator.computedRedirectToOidcAuthenticationUrl(tenantId);
    }

    function computedMarkedTenantNameParts(tenantName: string): string[] {
        if (util.isNullOrEmpty(tenantName)) {
            return [''];
        }

        const curatedSearchInput = tenantSearchInput.value.trim().toLowerCase();
        if (!tenantName.toLowerCase().includes(curatedSearchInput) || currentTenant.value?.organizationName.toLowerCase() === curatedSearchInput) {
            return [tenantName];
        }

        const searchIndexStart = tenantName.toLowerCase().indexOf(curatedSearchInput);
        const searchIndexEnd = searchIndexStart + curatedSearchInput.length;

        return [tenantName.substring(0, searchIndexStart), tenantName.substring(searchIndexStart, searchIndexEnd), tenantName.substring(searchIndexEnd)];
    }

    return {
        // state,
        tenantSearchInput,

        // computed
        user,
        userFullName,
        currentTenant,
        hasOtherTenants,
        otherTenants,

        // functions
        computeTenantContextLink,
        computedMarkedTenantNameParts,
    };
}

function setupRouting({ clientId }: { clientId: string }) {
    // computed
    const featureRoutes = computed(() => {
        // the dashboard is not displayed as a separate route, you can navigate to it by pressing on the navbar title
        return NavigationFeatureRoutes.filter((r) => r && r.name && r.name !== RoutePaths.dashboard.name);
    });

    const logoutUrl = computed(() => {
        return `${config().backendUrl}/oidc/slo`;
    });

    const accountSettingsUrl = computed(() => {
        return `${config().accountSettingsUrl}?client_id=${clientId}`;
    });

    const backendUrl = computed(() => {
        return config().backendUrl;
    });

    return {
        // computed
        featureRoutes,
        logoutUrl,
        accountSettingsUrl,
        backendUrl,
    };
}

function setupRouteInteractivity() {
    // state
    const routeMatchingMap = new Map<string, () => boolean>([
        [routes.domains.name, () => [routes.domains.name, routes.addDomain.name, routes.verifyDomain.name].indexOf(router.currentRoute.value.name!.toString()) > -1],
        [
            routes.singleSignOn.name,
            () =>
                [
                    routes.singleSignOn.name,
                    routes.addOrUpdateMyDomain.name,
                    routes.selectSaml2IdentityProvider.name,
                    routes.setupSaml2SsoWithAzureAd.name,
                    routes.setupSaml2SsoWithAdfs.name,
                    routes.setupSaml2SsoWithOkta.name,
                    routes.setupSaml2SsoWithHelloId.name,
                    routes.setupSaml2SsoWithOneLogin.name,
                    routes.setupSaml2SsoWithGoogleWorkspace.name,
                    routes.setupSamlSsoWithPingIdentity.name,
                    routes.setupSamlSsoWithOther.name,
                    routes.setupSsoWithSurfConext.name,
                    routes.selectOidcIdentityProvider.name,
                    routes.setupOidcSsoWithOkta.name,
                ].indexOf(router.currentRoute.value.name!.toString()) > -1,
        ],
        [routes.users.name, () => [routes.users.name, routes.userDetails.name].indexOf(router.currentRoute.value.name!.toString()) > -1],
        [routes.provisioning.name, () => [routes.provisioning.name, routes.provisioningLogs.name].indexOf(router.currentRoute.value.name!.toString()) > -1],
        [routes.groups.name, () => [routes.groups.name, routes.groupDetails.name].indexOf(router.currentRoute.value.name!.toString()) > -1],
        [
            routes.authenticationPolicies.name,
            () =>
                [
                    routes.authenticationPolicies.name,
                    routes.authenticationPolicy.name,
                    routes.authenticationPolicyPassword.name,
                    routes.authenticationPolicyIdentityProviders.name,
                    routes.authenticationPolicyTwoStepVerification.name,
                    routes.authenticationPolicyAuthorizedNetworks.name,
                    routes.authenticationPolicyGeoIpLocations.name,
                    routes.authenticationPolicySessionRestrictions.name,
                ].indexOf(router.currentRoute.value.name!.toString()) > -1,
        ],
    ]);

    const routeNameTranslations = new Map<string, string>([
        [routes.domains.name, i18n.t('nav_menu_route_name_domains').toString()],
        [routes.singleSignOn.name, 'Single Sign-On'],
        [routes.users.name, i18n.t('nav_menu_route_name_users').toString()],
        [routes.provisioning.name, i18n.t('nav_menu_route_name_provisioning').toString()],
        [routes.groups.name, i18n.t('nav_menu_route_name_groups').toString()],
        [routes.authenticationPolicies.name, i18n.t('nav_menu_route_name_policies').toString()],
    ]);

    // functions
    function isActiveRoute(route: RouteRecordName | undefined): boolean {
        if (!route) {
            return false;
        }

        const routeName = route.toString();

        if (!routeMatchingMap.has(routeName)) {
            return false;
        }

        return routeMatchingMap.get(routeName)!();
    }

    function getRouteTitleTranslation(route: RouteRecordName | undefined): string {
        if (!route) {
            return '';
        }

        const routeName = route.toString();

        if (routeNameTranslations.has(routeName)) {
            return routeNameTranslations.get(routeName)!;
        }

        return routeName;
    }

    function openHelpPage(): void {
        knowledgeBaseService.openContextAware();
    }

    return {
        // functions
        isActiveRoute,
        getRouteTitleTranslation,
        openHelpPage,
    };
}

export default defineComponent({
    name: 'AppNavbarComponent',

    components: {
        ModalDialog,
        NavbarJobsItem,
    },

    setup() {
        const authContext = setupAuthenticationContext();
        const uiReactivity = setupUiReactivity(authContext);
        const routing = setupRouting(uiReactivity);
        const routingInteractivity = setupRouteInteractivity();

        return {
            ...uiReactivity,
            ...authContext,
            ...routing,
            ...routingInteractivity,
        };
    },
});
