
import { defineComponent, ref, onMounted } from 'vue';
import { authStore, domainStore, mydomainStore, oidcIdpStore, saml2IdpStore, surfConextStore } from '@/store';
import locator from '@/util/locator';
import provisioningStore from '@/store/provisioning-store';

async function executeHandledActionsAsync(func: () => Promise<void>) {
    try {
        await func();
    } catch {
        // ignore all errors here, as we just want to perform the initial data fetch for all details
    }
}

export default defineComponent({
    name: 'AppInitSplashComponent',

    components: {},

    setup() {
        const title = ref(process.env.VUE_APP_NAME);

        onMounted(async () => {
            const isAuthenticated = await authStore.storeApi.dispatch(authStore.actions.authenticateAsync);

            if (!isAuthenticated) {
                locator.redirectToOidcAuthentication();
                return;
            }

            domainStore.storeApi.commit(domainStore.mutations.setStoreProcessingState, true);
            mydomainStore.storeApi.commit(mydomainStore.mutations.setProcessingState, true);
            saml2IdpStore.storeApi.commit(saml2IdpStore.mutations.setProcessingState, true);
            oidcIdpStore.storeApi.commit(oidcIdpStore.mutations.setProcessingState, true);
            surfConextStore.storeApi.commit(surfConextStore.mutations.setProcessingState, true);
            provisioningStore.storeApi.commit(provisioningStore.mutations.setProcessingState, true);

            try {
                await Promise.all([
                    executeHandledActionsAsync(async () => await domainStore.storeApi.dispatch(domainStore.actions.fetchAllDomainsAsync)),
                    executeHandledActionsAsync(async () => await mydomainStore.storeApi.dispatch(mydomainStore.actions.getMyDomainAsync)),
                    executeHandledActionsAsync(async () => await saml2IdpStore.storeApi.dispatch(saml2IdpStore.actions.getSaml2IdentityProviderAsync)),
                    executeHandledActionsAsync(async () => await oidcIdpStore.storeApi.dispatch(oidcIdpStore.actions.getOidcIdentityProviderAsync)),
                    executeHandledActionsAsync(async () => await surfConextStore.storeApi.dispatch(surfConextStore.actions.getSurfConextIdentityProviderAsync)),
                    executeHandledActionsAsync(async () => await provisioningStore.storeApi.dispatch(provisioningStore.actions.getScimAsync)),
                ]);
            } finally {
                domainStore.storeApi.commit(domainStore.mutations.setStoreProcessingState, false);
                mydomainStore.storeApi.commit(mydomainStore.mutations.setProcessingState, false);
                saml2IdpStore.storeApi.commit(saml2IdpStore.mutations.setProcessingState, false);
                oidcIdpStore.storeApi.commit(oidcIdpStore.mutations.setProcessingState, false);
                surfConextStore.storeApi.commit(surfConextStore.mutations.setProcessingState, false);
                provisioningStore.storeApi.commit(provisioningStore.mutations.setProcessingState, false);
            }
        });

        return {
            title,
        };
    },
});
