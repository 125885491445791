import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-426835ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "page-dashboard" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_welcome_pane = _resolveComponent("welcome-pane")!
  const _component_user_statistics_pane = _resolveComponent("user-statistics-pane")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_welcome_pane),
    _createVNode(_component_user_statistics_pane)
  ]))
}