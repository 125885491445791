import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';
import DashboardView from '@/views/Dashboard.vue';
import RoutePaths from './routes';

export const NavigationFeatureRoutes: RouteRecordRaw[] = [
    {
        path: RoutePaths.dashboard.path,
        name: RoutePaths.dashboard.name,
        component: DashboardView,
    },
    {
        path: RoutePaths.domains.path,
        name: RoutePaths.domains.name,
        component: () => import('@/views/domains/Domains.vue'),
    },
    {
        path: RoutePaths.singleSignOn.path,
        name: RoutePaths.singleSignOn.name,
        component: () => import('@/views/sso/SingleSignOn.vue'),
    },
    {
        path: RoutePaths.provisioning.path,
        name: RoutePaths.provisioning.name,
        component: () => import('@/views/provisioning/Provisioning.vue'),
    },
    {
        path: RoutePaths.users.path,
        name: RoutePaths.users.name,
        component: () => import('@/views/users/Users.vue'),
    },
    {
        path: RoutePaths.groups.path,
        name: RoutePaths.groups.name,
        component: () => import('@/views/groups/Groups.vue'),
    },
    {
        path: RoutePaths.authenticationPolicies.path,
        name: RoutePaths.authenticationPolicies.name,
        component: () => import('@/views/authenticationpolicies/AuthenticationPolicies.vue'),
    },
];

const FeatureRoutes: RouteRecordRaw[] = [
    {
        path: RoutePaths.addDomain.path,
        name: RoutePaths.addDomain.name,
        component: () => import('@/views/domains/AddDomain.vue'),
    },
    {
        path: RoutePaths.verifyDomain.path,
        name: RoutePaths.verifyDomain.name,
        component: () => import('@/views/domains/VerifyDomain.vue'),
    },
    {
        path: RoutePaths.addOrUpdateMyDomain.path,
        name: RoutePaths.addOrUpdateMyDomain.name,
        component: () => import('@/views/sso/mydomain/AddOrUpdateMyDomain.vue'),
    },
    {
        path: RoutePaths.selectSaml2IdentityProvider.path,
        name: RoutePaths.selectSaml2IdentityProvider.name,
        component: () => import('@/views/sso/saml2-identity-providers/SelectSaml2IdentityProvider.vue'),
    },
    {
        path: RoutePaths.setupSaml2SsoWithAzureAd.path,
        name: RoutePaths.setupSaml2SsoWithAzureAd.name,
        component: () => import('@/views/sso/saml2-identity-providers/SetupAzureAd.vue'),
    },
    {
        path: RoutePaths.setupSaml2SsoWithAdfs.path,
        name: RoutePaths.setupSaml2SsoWithAdfs.name,
        component: () => import('@/views/sso/saml2-identity-providers/SetupAdfs.vue'),
    },
    {
        path: RoutePaths.setupSaml2SsoWithOkta.path,
        name: RoutePaths.setupSaml2SsoWithOkta.name,
        component: () => import('@/views/sso/saml2-identity-providers/SetupOkta.vue'),
    },
    {
        path: RoutePaths.setupSaml2SsoWithHelloId.path,
        name: RoutePaths.setupSaml2SsoWithHelloId.name,
        component: () => import('@/views/sso/saml2-identity-providers/SetupHelloId.vue'),
    },
    {
        path: RoutePaths.setupSaml2SsoWithOneLogin.path,
        name: RoutePaths.setupSaml2SsoWithOneLogin.name,
        component: () => import('@/views/sso/saml2-identity-providers/SetupOneLogin.vue'),
    },
    {
        path: RoutePaths.setupSaml2SsoWithGoogleWorkspace.path,
        name: RoutePaths.setupSaml2SsoWithGoogleWorkspace.name,
        component: () => import('@/views/sso/saml2-identity-providers/SetupGoogleWorkspace.vue'),
    },
    {
        path: RoutePaths.setupSamlSsoWithPingIdentity.path,
        name: RoutePaths.setupSamlSsoWithPingIdentity.name,
        component: () => import('@/views/sso/saml2-identity-providers/SetupPingIdentity.vue'),
    },
    {
        path: RoutePaths.setupSamlSsoWithOther.path,
        name: RoutePaths.setupSamlSsoWithOther.name,
        component: () => import('@/views/sso/saml2-identity-providers/SetupOther.vue'),
    },
    {
        path: RoutePaths.setupSsoWithSurfConext.path,
        name: RoutePaths.setupSsoWithSurfConext.name,
        component: () => import('@/views/sso/other-identity-providers/SetupSurfConext.vue'),
    },
    {
        path: RoutePaths.selectOidcIdentityProvider.path,
        name: RoutePaths.selectOidcIdentityProvider.name,
        component: () => import('@/views/sso/oidc-identity-providers/SelectOidcIdentityProvider.vue'),
    },
    {
        path: RoutePaths.setupOidcSsoWithAzureAd.path,
        name: RoutePaths.setupOidcSsoWithAzureAd.name,
        component: () => import('@/views/sso/oidc-identity-providers/SetupAzureAd.vue'),
    },
    {
        path: RoutePaths.setupOidcSsoWithAdfs.path,
        name: RoutePaths.setupOidcSsoWithAdfs.name,
        component: () => import('@/views/sso/oidc-identity-providers/SetupAdfs.vue'),
    },
    {
        path: RoutePaths.setupOidcSsoWithOkta.path,
        name: RoutePaths.setupOidcSsoWithOkta.name,
        component: () => import('@/views/sso/oidc-identity-providers/SetupOkta.vue'),
    },
    {
        path: RoutePaths.setupOidcSsoWithOther.path,
        name: RoutePaths.setupOidcSsoWithOther.name,
        component: () => import('@/views/sso/oidc-identity-providers/SetupOther.vue'),
    },
    {
        path: RoutePaths.provisioningLogs.path,
        name: RoutePaths.provisioningLogs.name,
        component: () => import('@/views/provisioning/ProvisioningLogs.vue'),
    },
    {
        path: RoutePaths.userDetails.path,
        name: RoutePaths.userDetails.name,
        component: () => import('@/views/users/UserDetails/UserDetailsContainer.vue'),
    },
    {
        path: RoutePaths.groupDetails.path,
        name: RoutePaths.groupDetails.name,
        component: () => import('@/views/groups/EditGroup.vue'),
    },
    {
        path: RoutePaths.groupAdd.path,
        name: RoutePaths.groupAdd.name,
        component: () => import('@/views/groups/AddGroup.vue'),
    },
    {
        path: RoutePaths.authenticationPolicy.path,
        name: RoutePaths.authenticationPolicy.name,
        component: () => import('@/views/authenticationpolicies/AuthenticationPolicy/AuthenticationPolicyContainer.vue'),
        children: [
            {
                path: RoutePaths.authenticationPolicyIdentityProviders.path,
                name: RoutePaths.authenticationPolicyIdentityProviders.name,
                component: () => import('@/components/authenticationpolicies/AuthenticationPolicy/AuthenticationPolicyIdentityProviders/AuthenticationPolicyIdentityProvidersContainer.vue'),
            },
            {
                path: RoutePaths.authenticationPolicyPassword.path,
                name: RoutePaths.authenticationPolicyPassword.name,
                component: () => import('@/components/authenticationpolicies/AuthenticationPolicy/AuthenticationPolicyPassword/AuthenticationPolicyPasswordContainer.vue'),
            },
            {
                path: RoutePaths.authenticationPolicyTwoStepVerification.path,
                name: RoutePaths.authenticationPolicyTwoStepVerification.name,
                component: () => import('@/components/authenticationpolicies/AuthenticationPolicy/AuthenticationPolicyTwoStepVerification/AuthenticationPolicyTwoStepVerificationContainer.vue'),
            },
            {
                path: RoutePaths.authenticationPolicyAuthorizedNetworks.path,
                name: RoutePaths.authenticationPolicyAuthorizedNetworks.name,
                component: () => import('@/components/authenticationpolicies/AuthenticationPolicy/AuthenticationPolicyAuthorizedNetworks/AuthenticationPolicyAuthorizedNetworksContainer.vue'),
            },
            {
                path: RoutePaths.authenticationPolicyGeoIpLocations.path,
                name: RoutePaths.authenticationPolicyGeoIpLocations.name,
                component: () => import('@/components/authenticationpolicies/AuthenticationPolicy/AuthenticationPolicyGeoIpLocations/AuthenticationPolicyGeoIpLocationsContainer.vue'),
            },
            {
                path: RoutePaths.authenticationPolicySessionRestrictions.path,
                name: RoutePaths.authenticationPolicySessionRestrictions.name,
                component: () => import('@/components/authenticationpolicies/AuthenticationPolicy/AuthenticationPolicySessionRestrictions/AuthenticationPolicySessionRestrictionsContainer.vue'),
            },
        ],
    },
];

const StaticRoutes: RouteRecordRaw[] = [
    {
        path: '/:catchAll(.*)',
        name: RoutePaths.notFound.name,
        component: () => import('@/views/NotFound.vue'),
    },
];

export default createRouter({
    history: createWebHistory(),
    routes: [...NavigationFeatureRoutes, ...FeatureRoutes, ...StaticRoutes],
});
