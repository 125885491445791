export default class KnowledgeBaseContexts {
    public static readonly HOME = 'authentication-settings';
    public static readonly DOMAINS = 'domains';
    public static readonly SSO = 'single-sign-on';
    public static readonly USERS = 'users';
    public static readonly USER_DETAILS = 'users-user';
    public static readonly GROUPS = 'groups';
    public static readonly PROVISIONING = 'provisioning';
    public static readonly POLICIES = 'policies';
    public static readonly SSO_SAML_AZURE_AD = 'microsoft-entra-id-1';
    public static readonly SSO_OIDC_AZURE_AD = 'microsoft-entra-id-oidc';
    public static readonly SSO_SAML_ADFS = 'microsoft-adfs';
    public static readonly SSO_OIDC_ADFS = 'microsoft-adfs-oidc';
    public static readonly SSO_SAML_OKTA = 'okta-1';
    public static readonly SSO_OIDC_OKTA = 'okta-oidc';
    public static readonly SSO_SAML_HELLO_ID = 'helloid';
    public static readonly SSO_SAML_ONELOGIN = 'onelogin';
    public static readonly SSO_SAML_GOOGLE_WORKSPACE = 'google-workspace';
    public static readonly SSO_SAML_PING_IDENTITY = 'ping-identity';
    public static readonly SSO_SAML_OTHER = 'generic-saml';
    public static readonly SSO_OIDC_OTHER = 'your-oidc-provider';

    private static readonly AllContexts = [
        KnowledgeBaseContexts.HOME,
        KnowledgeBaseContexts.DOMAINS,
        KnowledgeBaseContexts.SSO,
        KnowledgeBaseContexts.SSO_SAML_AZURE_AD,
        KnowledgeBaseContexts.SSO_OIDC_AZURE_AD,
        KnowledgeBaseContexts.SSO_SAML_ADFS,
        KnowledgeBaseContexts.SSO_OIDC_ADFS,
        KnowledgeBaseContexts.SSO_SAML_OKTA,
        KnowledgeBaseContexts.SSO_OIDC_OKTA,
        KnowledgeBaseContexts.SSO_SAML_HELLO_ID,
        KnowledgeBaseContexts.SSO_SAML_ONELOGIN,
        KnowledgeBaseContexts.SSO_SAML_GOOGLE_WORKSPACE,
        KnowledgeBaseContexts.SSO_SAML_PING_IDENTITY,
        KnowledgeBaseContexts.SSO_SAML_OTHER,
        KnowledgeBaseContexts.SSO_OIDC_OTHER,
        KnowledgeBaseContexts.USERS,
        KnowledgeBaseContexts.USER_DETAILS,
        KnowledgeBaseContexts.GROUPS,
        KnowledgeBaseContexts.POLICIES,
        KnowledgeBaseContexts.PROVISIONING,
    ];

    public static hasContext(context: string): boolean {
        return this.AllContexts.indexOf(context) > -1;
    }
}
