import config from '@/config';
import httpClient from '@/http-client';
import ApiRequestPayload from '@/http-client/models/ApiRequestPayload';
import { StatusCodes } from 'http-status-codes';
import ApiClientError from '../error';
import MyDomainInputModel from './models/MyDomainInputModel';
import MyDomainModel from './models/MyDomainModel';

const routeRoot = '/v1.0/sso/mydomain';

export default {
    async getAsync(): Promise<MyDomainModel | undefined> {
        const response = await httpClient.getAsync(routeRoot);

        if (response.statusCode === StatusCodes.OK) {
            return response.data(MyDomainModel);
        }

        if (response.statusCode === StatusCodes.NO_CONTENT) {
            return void 0;
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async addAsync(myDomain: string): Promise<MyDomainModel> {
        const response = await httpClient.postAsync(`${routeRoot}`, new ApiRequestPayload(new MyDomainInputModel(myDomain), MyDomainInputModel));

        if (response.statusCode === StatusCodes.CREATED) {
            const createdMyDomain = response.data(MyDomainModel);

            if (!createdMyDomain) {
                throw new ApiClientError(response.statusCode);
            }

            return createdMyDomain;
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async updateAsync(myDomain: string): Promise<MyDomainModel> {
        const response = await httpClient.putAsync(`${routeRoot}`, new ApiRequestPayload(new MyDomainInputModel(myDomain), MyDomainInputModel));

        if (response.statusCode === StatusCodes.OK) {
            const createdMyDomain = response.data(MyDomainModel);

            if (!createdMyDomain) {
                throw new ApiClientError(response.statusCode);
            }

            return createdMyDomain;
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async deleteAsync(): Promise<void> {
        const response = await httpClient.deleteAsync(`${routeRoot}`);

        if (response.statusCode === StatusCodes.NO_CONTENT) {
            return;
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async fetchVismaMetadataXmlAsync(myDomain: string): Promise<string | undefined> {
        const vismaMetadataUrl = `https://${myDomain}.my.${config().identityServerDomain}/saml/metadata`;
        const vismaMetadataResponse = await httpClient.getAsync(vismaMetadataUrl, { responseType: 'text' });

        if (vismaMetadataResponse.isSuccess && vismaMetadataResponse.rawData) {
            return vismaMetadataResponse.rawData.toString();
        }

        return void 0;
    },

    getvismaMetadataXmlUrl(myDomain: string): string {
        const vismaMetadataUrl = `https://${myDomain}.my.${config().identityServerDomain}/saml/metadata`;
        return vismaMetadataUrl;
    },
};
