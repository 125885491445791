import router from '@/router';
import routes from '@/router/routes';
import KnowledgeBaseContexts from './contexts';

export { KnowledgeBaseContexts };

const helpPageRoutingMap = new Map<string, string>([
    [routes.dashboard.name, KnowledgeBaseContexts.HOME],
    [routes.domains.name, KnowledgeBaseContexts.DOMAINS],
    [routes.addDomain.name, KnowledgeBaseContexts.DOMAINS],
    [routes.verifyDomain.name, KnowledgeBaseContexts.DOMAINS],
    [routes.singleSignOn.name, KnowledgeBaseContexts.SSO],
    [routes.users.name, KnowledgeBaseContexts.USERS],
    [routes.userDetails.name, KnowledgeBaseContexts.USER_DETAILS],
    [routes.groups.name, KnowledgeBaseContexts.GROUPS],
    [routes.authenticationPolicies.name, KnowledgeBaseContexts.POLICIES],
    [routes.provisioning.name, KnowledgeBaseContexts.PROVISIONING],
    [routes.addOrUpdateMyDomain.name, KnowledgeBaseContexts.SSO],
    [routes.selectSaml2IdentityProvider.name, KnowledgeBaseContexts.SSO],
    [routes.selectOidcIdentityProvider.name, KnowledgeBaseContexts.SSO],
    [routes.setupSaml2SsoWithAzureAd.name, KnowledgeBaseContexts.SSO_SAML_AZURE_AD],
    [routes.setupOidcSsoWithAzureAd.name, KnowledgeBaseContexts.SSO_OIDC_AZURE_AD],
    [routes.setupSaml2SsoWithAdfs.name, KnowledgeBaseContexts.SSO_SAML_ADFS],
    [routes.setupOidcSsoWithAdfs.name, KnowledgeBaseContexts.SSO_OIDC_ADFS],
    [routes.setupSaml2SsoWithOkta.name, KnowledgeBaseContexts.SSO_SAML_OKTA],
    [routes.setupOidcSsoWithOkta.name, KnowledgeBaseContexts.SSO_OIDC_OKTA],
    [routes.setupSaml2SsoWithHelloId.name, KnowledgeBaseContexts.SSO_SAML_HELLO_ID],
    [routes.setupSaml2SsoWithOneLogin.name, KnowledgeBaseContexts.SSO_SAML_ONELOGIN],
    [routes.setupSaml2SsoWithGoogleWorkspace.name, KnowledgeBaseContexts.SSO_SAML_GOOGLE_WORKSPACE],
    [routes.setupSamlSsoWithPingIdentity.name, KnowledgeBaseContexts.SSO_SAML_PING_IDENTITY],
    [routes.setupSamlSsoWithOther.name, KnowledgeBaseContexts.SSO_SAML_OTHER],
    [routes.setupOidcSsoWithOther.name, KnowledgeBaseContexts.SSO_OIDC_OTHER],
]);

const knowledgeBaseUrl = 'https://docs.connect.visma.com';

export default {
    open(context: string): void {
        window.open(`${knowledgeBaseUrl}/${context}`, 'knowledge-base');
    },

    openContextAware(): void {
        const currentRoute = router.currentRoute.value.name?.toString();

        if (!currentRoute) {
            this.open(KnowledgeBaseContexts.HOME);
            return;
        }

        if (helpPageRoutingMap.has(currentRoute)) {
            this.open(helpPageRoutingMap.get(currentRoute)!);
        } else {
            this.open(KnowledgeBaseContexts.HOME);
        }
    },
};
