import { MyDomainStoreState } from './state';

export default {
    myDomain(state: MyDomainStoreState): string | undefined {
        return state.myDomain;
    },

    myDomainUri(state: MyDomainStoreState): string | undefined {
        return state.myDomainUri;
    },

    vismaMetadataXml(state: MyDomainStoreState): string | undefined {
        return state.vismaMetadataXml;
    },

    vismaMetadataXmlUrl(state: MyDomainStoreState): string | undefined {
        return state.vismaMetadataXmlUrl;
    },

    isModifiable(state: MyDomainStoreState): boolean {
        return !state.myDomain || state.isModifiable;
    },

    isProcessing(state: MyDomainStoreState): boolean {
        return state.isProcessing;
    },

    isUnauthorizedToViewMyDomain(state: MyDomainStoreState): boolean {
        return state.isUnauthorizedToViewMyDomain;
    },

    isDataLoaded(state: MyDomainStoreState): boolean {
        return state.isDataLoaded;
    },
};
