export class RouteInfo {
    constructor(name: string, path: string, isActive = true) {
        this.name = name;
        this.path = path;
        this.isActive = isActive;
    }

    name: string;

    path: string;

    isActive: boolean;
}

export default {
    // Dashboard
    dashboard: new RouteInfo('Dashboard', '/'),

    // Domains
    domains: new RouteInfo('Domains', '/domain'),
    addDomain: new RouteInfo('AddDomain', '/domain/add'),
    verifyDomain: new RouteInfo('VerifyDomain', '/domain/:domainName/verify'),

    // Single Sign-On
    singleSignOn: new RouteInfo('SingleSignOn', '/sso'),
    addOrUpdateMyDomain: new RouteInfo('AddOrUpdateMyDomain', '/sso/mydomain'),
    selectSaml2IdentityProvider: new RouteInfo('SelectSaml2IdentityProvider', '/sso/saml2-idp'),
    setupSaml2SsoWithAzureAd: new RouteInfo('Saml2SsoWithAzureAd', '/sso/saml2-idp/azure-ad'),
    setupSaml2SsoWithAdfs: new RouteInfo('Saml2SsoWithAdfs', '/sso/saml2-idp/adfs'),
    setupSaml2SsoWithOkta: new RouteInfo('Saml2SsoWithOkta', '/sso/saml2-idp/okta'),
    setupSaml2SsoWithHelloId: new RouteInfo('Saml2SsoWithHelloId', '/sso/saml2-idp/hello-id'),
    setupSaml2SsoWithOneLogin: new RouteInfo('Saml2SsoWithOneLogin', '/sso/saml2-idp/one-login'),
    setupSaml2SsoWithGoogleWorkspace: new RouteInfo('Saml2SsoWithGoogleWorkspace', '/sso/saml2-idp/google-workspace'),
    setupSamlSsoWithPingIdentity: new RouteInfo('SamlSsoWithPingIdentity', '/sso/saml2-idp/ping-identity'),
    setupSamlSsoWithOther: new RouteInfo('SamlSsoWithOther', '/sso/saml2-idp/other'),
    setupSsoWithSurfConext: new RouteInfo('SsoWithSurfConext', '/sso/surfconext'),
    selectOidcIdentityProvider: new RouteInfo('SelectOidcIdentityProvider', '/sso/oidc-idp'),
    setupOidcSsoWithAzureAd: new RouteInfo('OidcSsoWithAzureAd', '/sso/oidc-idp/azure-ad'),
    setupOidcSsoWithAdfs: new RouteInfo('OidcSsoWithAdfs', '/sso/oidc-idp/adfs'),
    setupOidcSsoWithOkta: new RouteInfo('OidcSsoWithOkta', '/sso/oidc-idp/okta'),
    setupOidcSsoWithOther: new RouteInfo('OidcSsoWithOther', '/sso/oidc-idp/other'),

    // Users
    users: new RouteInfo('Users', '/user'),
    userDetails: new RouteInfo('UserDetails', '/user/:userId'),

    // Jobs
    jobs: new RouteInfo('Jobs', '/jobs'),
    jobDetails: new RouteInfo('JobDetails', '/jobs/:id'),

    // Provisioning
    provisioning: new RouteInfo('Provisioning', '/provisioning'),
    provisioningLogs: new RouteInfo('ProvisioningLogs', '/provisioning/logs'),

    // Groups
    groups: new RouteInfo('Groups', '/groups'),
    groupDetails: new RouteInfo('GroupDetails', '/groups/details/:groupId'),
    groupAdd: new RouteInfo('GroupAdd', '/groups/add'),

    // Authentication policies
    authenticationPolicies: new RouteInfo('Policies', '/policies'),
    authenticationPolicy: new RouteInfo('Policy', '/policies/:id'),
    authenticationPolicyIdentityProviders: new RouteInfo('PolicyIdentityProviders', '/policies/:id/identityProviders'),
    authenticationPolicyPassword: new RouteInfo('PolicyPassword', '/policies/:id/password'),
    authenticationPolicyTwoStepVerification: new RouteInfo('PolicyTwoStepVerification', '/policies/:id/twoStepVerification'),
    authenticationPolicyAuthorizedNetworks: new RouteInfo('PolicyAuthorizedNetworks', '/policies/:id/networks'),
    authenticationPolicyGeoIpLocations: new RouteInfo('PolicyGeoIpLocations', '/policies/:id/locations'),
    authenticationPolicySessionRestrictions: new RouteInfo('PolicySessionRestrictions', '/policies/:id/session'),

    // Generic
    notFound: new RouteInfo('NotFound', '/not-found'),
};
