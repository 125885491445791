export enum Saml2ProviderType {
    Other,
    AzureAd,
    Adfs,
    Okta,
    HelloId,
    OneLogin,
    GoogleWorkspace,
    PingIdentity,
    SurfConext, // this isn't a SAML provider, but for now we include it with the SAML providers
}
